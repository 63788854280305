// const { initializeApp, applicationDefault, cert } = require('firebase-admin/app')
// const { getFirestore, Timestamp, FieldValue } = require('firebase-admin/firestore')


// initializeApp();

// const db = getFirestore();

// export default db


// // import { initializeApp } from "firebase/app";
// // import { getAnalytics } from "firebase/analytics";


// // const firebaseConfig = {
// //   apiKey: "AIzaSyAcQkcBeP5nugDnIk4ok4oLpb-7fyOh6GA",
// //   authDomain: "friendshouse-testreact.firebaseapp.com",
// //   projectId: "friendshouse-testreact",
// //   storageBucket: "friendshouse-testreact.appspot.com",
// //   messagingSenderId: "456026783153",
// //   appId: "1:456026783153:web:28b6c2e2429b6ad2bb1190",
// //   measurementId: "G-M69FHYBP0Y"
// // };

// // const app = initializeApp(firebaseConfig);
// // const analytics = getAnalytics(app);

import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

// TODO: Replace the following with your app's Firebase project configuration
// See: https://firebase.google.com/docs/web/learn-more#config-object
const firebaseConfig = {
  apiKey: "AIzaSyAcQkcBeP5nugDnIk4ok4oLpb-7fyOh6GA",
  authDomain: "friendshouse-testreact.firebaseapp.com",
  databaseURL: "https://friendshouse-testreact-default-rtdb.firebaseio.com",
  projectId: "friendshouse-testreact",
  storageBucket: "friendshouse-testreact.appspot.com",
  messagingSenderId: "456026783153",
  appId: "1:456026783153:web:28b6c2e2429b6ad2bb1190",
  measurementId: "G-M69FHYBP0Y"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);


// Initialize Cloud Firestore and get a reference to the service
export const db = getFirestore(app);
