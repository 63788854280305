import React from 'react'
import {
  BrowserRouter as Router,
  Route,
  Switch
} from "react-router-dom"

import Onboarding from '../onboarding'
import Welcome from '../welcome'
import TopNav from '../../components/navigation/top-nav'
import SideNav from '../../components/navigation/side-nav'
import Footer from '../../components/navigation/footer'

import styles from './styles.module.css';

function App() {
  return (
    <Router>
        
        <div className={styles.root}>
          
          <TopNav />

          <Switch>

            <Route exact path="/">
              <Welcome />
            </Route>

            <Route exact path="/welcome">
              <Welcome />
            </Route>

            <Route path="/onboarding">
              <Onboarding />
            </Route>

          </Switch>

          <Footer />

        </div>

      </Router>
  )
}

export default App
