 

const TopNav = () => {
     return (
      <header id="header-container" className="fullwidth">
   
        <div id="header">
          <div className="container">
      
            <div className="left-side">
      
              <div id="logo">
                <a href="./welcome"><img src="./assets/images/friendshomes-logo-i2.png" data-sticky-logo="./assets/images/friendshomes-logo-i2.png" alt="" /></a>
              </div>
      
              {/* <div className="mmenu-trigger">
                <button className="hamburger hamburger--collapse" type="button">
                        <span className="hamburger-box">
                           <span className="hamburger-inner"></span>
                        </span>
                </button>
              </div> */}
      
              <nav id="navigation" className="style-1">
                <ul id="responsive">
                  {/* <li>
                     <a href="" [routerLink]="'./app-main-view'" routerLinkActive="current">Home</a>
                  </li>
      
                  <li>
                     <a href="" [routerLink]="'./app-listings'" routerLinkActive="current">Listings</a>
                  </li> */}
                </ul>
              </nav>
              <div className="clearfix"></div>
            </div>
      
            {/* <div className="right-side"> */}
              {/* <div className="header-widget"> */}
                {/* <div className="user-menu"> */}
                  {/* <div className="user-name"> */}
                     {/* <span> */}
                        {/* <img src="{{userService.getUserPhoto()}}" alt="" /> */}
                     {/* </span> */}
                     {/* Hi, {{userService.getUserName()}}! */}
                  {/* </div> */}
                  {/* <ul> */}
                    {/* <li><a href="" [routerLink]="'./app-user-profile'"> <i className="sl sl-icon-settings"></i>Profile</a></li> */}
                    {/* <li><a href="dashboard-messages.html"><i className="sl sl-icon-envelope-open"></i> Messages</a></li> */}
                    {/* <li><a href="dashboard-bookings.html"><i className="fa fa-calendar-check-o"></i> Bookings</a></li> */}
                    {/* <li><a href="" (click)="authService.SignOut()"><i className="sl sl-icon-power" ></i> Logout</a></li> */}
                  {/* </ul> */}
                {/* </div> */}
      
      
                {/* <a className="button border with-icon" [routerLink]="'./app-create-listing'" routerLinkActive="active">Add Listing <i className="sl sl-icon-plus"></i></a> */}
              {/* </div> */}
            {/* </div> */}
          </div>
        </div>
      <div className="clearfix"></div>
      </header>
      
      
     )
         
}

export default TopNav