import styles from './styles.module.css'



const Welcome = () => {

    return (
    <div className="dark-header_">

        {/* <!-- Wrapper --> */}
        <div id="wrapper">
        {/* <!-- Banner
        ================================================== --> */}

            {/* <!-- Item --> */}
            {/* <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="boxed-photo-banner">
                        
                        <div className="boxed-photo-banner-text">
                            <h3>We are a community of friends.&nbsp;  </h3>
                            <p>Earn extra income and unlock new opportunities by advertising your business</p>
                        </div>
                        
                        <img src="./assets/images/home-carousel-01.jpg" alt="" />
                        </div>
                    </div>
                </div>
            </div> */}

            {/* <div className="container" style={{backgroundImage: 'url(./assets/images/home-carousel-01.jpg)'}}>
            <div className="row">
                <div className="col-md-12">
                    <h3>We are a community of friends.&nbsp;  </h3>
                    <h3><a href="">Be our guest&nbsp;&nbsp;</a></h3>
                </div>
            </div>
            </div> */}

            {/* <div className={styles.waitlist__container} style={{backgroundImage: './assets/images/home-carousel-01.jpg '}}>
                <div className="col-md-12">
                    <h3>We are a community of friends.&nbsp;  </h3>
                    <h3><a href="">Be our guest&nbsp;&nbsp;</a></h3>
                </div>
            </div> */}

        

            {/* <!-- Join Waitlist --> */}
            {/* <div className={styles.waitlist}>

                <div className="main-search-input-item">
                    <h4>Ready to join our community?</h4>
                </div>
            
                <button className="button">Join the waitlist</button>
            </div> */}


        <div className="container margin-top-80">
        <div className={styles.waitlist}>
                
                <div className="main-search-input-item">
                    <h4>Ready to join our community?</h4>
                </div>
            
                <a className="button" href='./onboarding'>Join the waitlist</a>
            </div>
        <div className="row">
            <div className="col-md-12">
            <h3 className="headline margin-top-0 margin-bottom-40">
                <strong className="headline-with-separator">How It Works?</strong>
            </h3>
            </div>
            <div className="col-md-5">
            <a href="#" className="icon-box-v3">
                <div className="ibv3-icon">
                <i className="im im-icon-Add-User"></i>
                </div>
                <div className="ibv3-content">
                <h4>Create an Account</h4>
                <p>Integer dapibus purus sit amet metus scelerisque hendrerit non a urna</p>
                </div>
            </a>
            <a href="#" className="icon-box-v3">
                <div className="ibv3-icon">
                <i className="im im-icon-Add-File"></i>
                </div>
                <div className="ibv3-content">
                <h4>Add Listings</h4>
                <p>Phasellus id nulla id tortor laoreet tempor et non risus className aptent taciti</p>
                </div>
            </a>
            <a href="#" className="icon-box-v3">
                <div className="ibv3-icon">
                <i className="im im-icon-Queen"></i>
                </div>
                <div className="ibv3-content">
                <h4>Get Exposure</h4>
                <p>Vestibulum pretium massa in bibendum nam mollis quam et feugiat consectetur</p>
                </div>
            </a>
            </div>
            <div className="col-md-7">
            <div className="svg-alignment">
                <img src="./assets/images/image_placeholder.svg" style={{width: '60%'}} alt="" />
            </div>
            </div>
        </div>
        </div>


        <section className="fullwidth border-top padding-bottom-80 padding-top-80 margin-top-80">
        <div className="container">
            <div className="row">
            <div className="col-md-12">
                <div className="boxed-photo-banner">
                {/* <!-- Infobox --> */}
                <div className="boxed-photo-banner-text">
                    <h2>Join Our Community</h2>
                    <p>Earn extra income and unlock new opportunities by advertising your business</p>
                    <a href="listings-list-with-sidebar.html" className="button margin-top-25">Become a Host</a>
                </div>
                {/* <!-- Infobox / End --> */}
                <img src="./assets/images/slider-bg-02.jpg" alt="" />
                </div>
            </div>
            </div>
        </div>
        </section>


        {/* <!-- Back To Top Button --> */}
        <div id="backtotop"><a href="#"></a></div>


        </div>

        {/* <!-- Wrapper / End --> */}

        <script type="text/javascript" src="./assets/./assets/scripts/jquery-3.6.0.min.js"></script>
        <script type="text/javascript" src="./assets/scripts/jquery-migrate-3.3.2.min.js"></script>
        <script type="text/javascript" src="./assets/scripts/mmenu.min.js"></script>
        <script type="text/javascript" src="./assets/scripts/chosen.min.js"></script>
        <script type="text/javascript" src="./assets/scripts/slick.min.js"></script>
        <script type="text/javascript" src="./assets/scripts/rangeslider.min.js"></script>
        <script type="text/javascript" src="./assets/scripts/magnific-popup.min.js"></script>
        <script type="text/javascript" src="./assets/scripts/waypoints.min.js"></script>
        <script type="text/javascript" src="./assets/scripts/counterup.min.js"></script>
        <script type="text/javascript" src="./assets/scripts/jquery-ui.min.js"></script>
        <script type="text/javascript" src="./assets/scripts/tooltips.min.js"></script>
        <script type="text/javascript" src="./assets/scripts/custom.js"></script>


        <script src="./assets/scripts/leaflet.min.js"></script>

        <script src="./assets/scripts/leaflet-markercluster.min.js"></script>
        <script src="./assets/scripts/leaflet-gesture-handling.min.js"></script>
        <script src="./assets/scripts/leaflet-listeo.js"></script>

        <script src="./assets/scripts/leaflet-autocomplete.js"></script>
        <script src="./assets/scripts/leaflet-control-geocoder.js"></script>
    </div>
    


    )
}

export default Welcome