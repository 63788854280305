const Footer = () => {
     return (
      <div id="footer" className="sticky-footer">
        <div className="container">
          <div className="row">
            <div className="col-md-5 col-sm-6">
              <img className="footer-logo" src="../../../../assets/images/friendshomes-logo-i2.png" alt="" />
              <br />
              <p>Morbi convallis bibendum urna ut viverra. Maecenas quis consequat libero, a feugiat eros. Nunc ut lacinia tortor morbi ultricies laoreet ullamcorper phasellus semper.</p>
            </div>
      
            <div className="col-md-4 col-sm-6 ">
              <h4>Helpful Links</h4>
              <ul className="footer-links">
                <li><a href="#">Login</a></li>
                <li><a href="#">Sign Up</a></li>
                <li><a href="#">My Account</a></li>
                <li><a href="#">Add Listing</a></li>
                <li><a href="#">Pricing</a></li>
                <li><a href="#">Privacy Policy</a></li>
              </ul>
      
              <ul className="footer-links">
                <li><a href="#">FAQ</a></li>
                <li><a href="#">Blog</a></li>
                <li><a href="#">Our Partners</a></li>
                <li><a href="#">How It Works</a></li>
                <li><a href="#">Contact</a></li>
              </ul>
              <div className="clearfix"></div>
            </div>
      
            <div className="col-md-3  col-sm-12">
              <h4>Contact Us</h4>
              <div className="text-widget">
                E-Mail:<span> <a href="#">contact@friends.homes</a> </span><br />
              </div>
      
              <ul className="social-icons margin-top-20">
                <li><a className="facebook" href="#"><i className="icon-facebook"></i></a></li>
                <li><a className="twitter" href="#"><i className="icon-twitter"></i></a></li>
                <li><a className="gplus" href="#"><i className="icon-gplus"></i></a></li>
                <li><a className="vimeo" href="#"><i className="icon-vimeo"></i></a></li>
              </ul>
      
            </div>
      
          </div>
      
          <div className="row">
            <div className="col-md-12">
              <div className="copyrights">© 2022 FriendsHomes LLC. All Rights Reserved.</div>
            </div>
          </div>

        </div>
      
      </div>
     )
         
}

export default Footer