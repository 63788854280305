import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as yup from 'yup'

import {db} from '../../firebase'
import { collection, addDoc } from "firebase/firestore"; 

import styles from './styles.module.css'

enum Options {
  "one" = "remotlyFullTime",
  "two" = "remotelyPart-time",
  "three" = "office",
  "four" = "retired",
  "five" = "notActively "
}

const OptionsEnumTextMap = {
    [Options.one]: "I work remotely all the time",
    [Options.two]: "I work remotely part-time",
    [Options.three]: "I work out of the company office",
    [Options.four]: "I am retired",
    [Options.five]: "I'm not actively employed"
}

enum Sources {
  "one" = "byMember",
  "two" = "friend",
  "three" = "Instagram",
  "four" = "Facebook",
  "five" = "Advertisement",
  "six" = "Other"
}

const SourcesEnumTextMap = {
  [Sources.one]: "Referred by an existing member",
  [Sources.two]: "Heard from a friend",
  [Sources.three]: "Instagram",
  [Sources.four]: "Facebook",
  [Sources.five]: "Advertisement",
  [Sources.six]: "Other"

}



const onboardingSchema = yup.object().shape({
  name: yup.string().required(),
  email: yup.string().email().required(),
  city: yup.string().required(),
  linkedin: yup.string().url().required(),
  telegram: yup.string().required(),
  facebook: yup.string().url(),
  otherSocialMedia: yup.string().url(),
  willingnessToListProperty: yup.string().required(),
  options: yup.string().required(),
  sources: yup.string().required(),
})


const Onboarding = () => {
  const handleSubmit = async (values: any) => {
    try {
      const docRef = await addDoc(collection(db, "waitlist"), {
        ...values
      });
      console.log("Document written with ID: ", docRef.id);
    } catch (e) {
      console.error("Error adding document: ", e);
    }
  }

    return (
        <div className={"container"}>
            <Formik
              initialValues={{
                name: '',
                email: '',
                city: '',
                linkedin: '',
                telegram: '',
                facebook: '',
                otherSocialMedia: '',
                willingnessToListProperty: '',
                options: '',
                sources: '',
              }}
              onSubmit={handleSubmit}
              validationSchema={onboardingSchema}
            >
              {({ errors, touched, isValidating }) => (
                <Form>
                  <div className={styles.content}>
                    <div className={styles.formContainer}>
                      {errors.name && touched.name && <div className={styles.errors}>{errors.name}</div>}
                      <div className="main-search-input" >
                        <div className="main-search-input-item">
                          <Field type="text" placeholder="What is your name?" name="name" />
                        </div>
                      </div>
                    </div>
                    
                    <div className={styles.formContainer}>
                      {errors.email && touched.email && <div className={styles.errors}>{errors.email}</div>}
                      <div className="main-search-input" >
                        <div className="main-search-input-item">
                          <Field type="text" placeholder="What is your email?" name="email" />
                        </div>
                      </div>
                    </div>
                    
                    <div className={styles.formContainer}>
                      {errors.city && touched.city && <div className={styles.errors}>{errors.city}</div>}
                      <div className="main-search-input" >
                        <div className="main-search-input-item">
                          <Field type="text" placeholder="What is your city?" name="city" />
                        </div>
                      </div>
                    </div>

                    <div className={styles.formContainer}>
                      {errors.linkedin && touched.linkedin && <div className={styles.errors}>{errors.linkedin}</div>}
                      <div className="main-search-input" >
                        <div className="main-search-input-item">
                          <Field type="text" placeholder="What is your LinkedIn Profile URL?" name="linkedin" />
                        </div>
                      </div>
                    </div>

                    <div className={styles.formContainer}>
                      {errors.telegram && touched.telegram && <div className={styles.errors}>{errors.telegram}</div>}
                      <div className="main-search-input" >
                        <div className="main-search-input-item">
                          <Field type="text" placeholder="What is your telegram User name?" name="telegram"/>
                        </div>
                      </div>
                    </div>

                    <div className={styles.formContainer}>
                      {errors.facebook && touched.facebook && <div className={styles.errors}>{'must be a valid URL'}</div>}
                      <div className="main-search-input" >
                        <div className="main-search-input-item">
                          <Field type="text" placeholder="What is your facebook Profile link?" name="facebook"/>
                        </div>
                      </div>
                    </div>

                    <div className={styles.formContainer}>
                      {errors.otherSocialMedia && touched.otherSocialMedia && <div className={styles.errors}>{'must be a valid URL'}</div>}
                      <div className="main-search-input" >
                        <div className="main-search-input-item">
                          <Field type="text" placeholder="Other social media profile links (e.g. Instagram, Twitter)" name="otherSocialMedia" />
                        </div>
                      </div>
                    </div>

                      <div className={styles.radioGroupContainer}>
                        <div className={styles.radioGroup}>Are you willing to list your property now or in the future?</div>
                        {errors.willingnessToListProperty && touched.willingnessToListProperty && <div className={styles.errorsRadioGrouop}>{'This field is required'}</div>}
                        <div role="group" aria-labelledby="my-radio-group">

                            <label>
                              <div className={styles.radioItem}>
                                <Field type="radio" name="willingnessToListProperty" value="Yes"/>
                                <div>Yes</div>
                              </div> 
                            </label>

                            <label>
                              <div className={styles.radioItem}>
                                <Field type="radio" name="willingnessToListProperty" value="No" />
                                <div>No</div>
                              </div>
                            </label>

                            <label>
                              <div className={styles.radioItem}>
                                <Field type="radio" name="willingnessToListProperty" value="Not sure"/>
                                <div>Not sure</div>
                              </div>
                            </label>
                        </div>
                      </div>
                      
          
                      <div className={styles.radioGroupContainer}>
                          <div className={styles.radioGroup}>Select the option that fits you best</div>
                          {errors.options && touched.options && <div className={styles.errorsRadioGrouop}>{'This field is required'}</div>}
                          <div role="group" aria-labelledby="my-radio-group">

                            <label>
                              <div className={styles.radioItem}>
                                <Field type="radio" name="options" value={Options.one}/>
                                <div>{OptionsEnumTextMap[Options.one]}</div>
                              </div> 
                            </label>

                            <label>
                              <div className={styles.radioItem}>
                                <Field type="radio" name="options" value={Options.two}/>
                                <div>{OptionsEnumTextMap[Options.two]}</div>
                              </div>
                            </label>

                            <label>
                              <div className={styles.radioItem}>
                                <Field type="radio" name="options" value={Options.three}/>
                                <div>{OptionsEnumTextMap[Options.three]}</div>
                              </div>
                            </label>

                            <label>
                              <div className={styles.radioItem}>
                                <Field type="radio" name="options" value={Options.four}/>
                                <div>{OptionsEnumTextMap[Options.four]}</div>
                              </div>
                            </label>

                            <label>
                              <div className={styles.radioItem}>
                                <Field type="radio" name="options" value={Options.five}/>
                                <div>{OptionsEnumTextMap[Options.five]}</div>
                              </div>
                            </label>
                        </div>
                    </div>

                    
                    <div className={styles.radioGroupContainer}>
                    <div className={styles.radioGroup}>How did you hear about Friends Homes?</div>
                    {errors.sources && touched.sources && <div className={styles.errorsRadioGrouop}>{'This field is required'}</div>}
                    <div role="group" aria-labelledby="my-radio-group">
                        <label>
                          <div className={styles.radioItem}>
                            <Field type="radio" name="sources" value={Sources.one}/>
                            <div>Referred by an existing member</div>
                          </div> 
                        </label>

                        <label>
                          <div className={styles.radioItem}>
                            <Field type="radio" name="sources" value={Sources.two}/>
                            <div>Heard from a friend</div>
                          </div>
                        </label>

                        <label>
                          <div className={styles.radioItem}>
                            <Field type="radio" name="sources" value={Sources.three}/>
                            <div>Instagram</div>
                          </div>
                        </label>

                        <label>
                          <div className={styles.radioItem}>
                            <Field type="radio" name="sources" value={Sources.four}/>
                            <div>Facebook</div>
                          </div>
                        </label>

                        <label>
                          <div className={styles.radioItem}>
                            <Field type="radio" name="sources" value={Sources.five}/>
                            <div>Advertisement</div>
                          </div>
                        </label>

                        <label>
                          <div className={styles.radioItem}>
                            <Field type="radio" name="sources" value={Sources.six}/>
                            <div>Other</div>
                          </div>
                        </label>
                    </div>
                    </div>
                    

                    <button type="submit" className={'button'}>Submit</button>
                  </div>
                </Form>
              )}
            </Formik>
        </div>
    )
}

export default Onboarding