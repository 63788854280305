import React from 'react';
import ReactDOM from 'react-dom/client';

import App from './pages/app/index';
import reportWebVitals from './reportWebVitals';
import './firebase'
// import 'core-js/es6/promise';
// import 'core-js/es6/set';
// import 'core-js/es6/map';

import './index.css';
import "./styles.css";
import "./css/main-color.css";
import "./css/icons.css";
import "./css/bootstrap-grid.css";
import "./css/custom.css";

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

reportWebVitals();
